
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import {baseUrl, limit, formatDate} from "../../comman/config";
import * as XLSX from 'xlsx';


import { DateSessionReportFilter } from "../../components/Filter/DateSessionReportFilter";
import Loader from '../../components/Loader';

const DEFAULT_METHODS = [{
    "value": 1,
    "label": "Kgs"
},
{
    "value": 3,
    "label": "Nos"
}
]

function List() {

    const today = new Date().toISOString().split("T")[0];    
    const [filteredList, setFilteredList] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [session, setSession] = useState("");
    const [item, setItem] = useState("");
    const [method, setMethod] = useState([]);
    const [summary, setSummary] = useState(true);
    const [reportType, setReportType] = useState(1);

    useEffect(() => {
        
            getShortageList(); 
        
    }, [fromDate,toDate, session, item, method]);


    useEffect(() => {
        let filterelist;
        if(method && method.length > 0){
            const methodIds = method.map(method => method.value);
            filterelist = reportList.filter((item) => methodIds.includes(item.measurements));
        } else{
            filterelist = reportList;
        }  
        setFilteredList(filterelist);
    }, [ method, reportList]);
    const getShortageList = async () => {
        
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/reports/master_report?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&session=${session?.value}&item=${JSON.stringify(item)}&method=${JSON.stringify(method)}`, {
           
        });
       
        
        setPopupOpen(false);
        setReportList(response.data.data);
        setFilteredList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handelFilterChange= (fromDate, toDate, session, item, method) => {
        console.log(item);
        setFromDate(fromDate);
        setToDate(toDate);
        setSession(session);
        setItem(item);
        setMethod(method);
        
        
    };

    // const hadelSummary = (summary) => {
    //     setSummary(summary);
    // }

    const handelReportChange = (data) => {
        console.log(data);
        setReportType(data.value);
    }
   
    // const handleExport = () => {
    //     const header = [];
    //     header.push("Date")
    //     header.push("Session")
    //     header.push("Menu Item")
    //     header.push("Estimated (Persons)")
    //     header.push("Production (Persons)")
    //     header.push("Pass Issued")
    //     header.push("Dish count")
    //     header.push("Excess Bowl (Nos)")
    //     header.push("Excess QTY")
    //     header.push("Method Type")
    //     header.push("Excess RM (Kg)")
    //     header.push("Planned RAS")
    //     header.push("Current RAS")

        
    //     const csv = [header].concat(
    //         filteredList.map((item, index) => {
    //             let resultArray = [];
    //             resultArray.push(formatDate(item.date))
    //             resultArray.push(item.session_name)
    //             resultArray.push(`${item.item_name} (${item.eng_name})`);
    //             resultArray.push(parseInt(item.raw_material?.estimated_count));
    //             resultArray.push(item.food_qty ?? 0);
    //             resultArray.push(item.pass_issued ?? 0);
    //             resultArray.push(item.final_dish_count ?? 0);
    //             resultArray.push(item.excess_bowl_qty ?? 0);
    //             resultArray.push(item.excess_fg_qty ?? 0)
    //             resultArray.push(`${item.methodName?.name ?? '-'}`)
    //             resultArray.push(item.excess_rm_qty ?? 0);
    //             resultArray.push(item.raw_material.ras_qty_per_100_person);
    //             resultArray.push(item.raw_mat_per_100_person ?? 0);
    //             return resultArray.join(',');
    //         })
    //     ).join('\n');
    //     const blob = new Blob([csv], { type: 'text/csv' });
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.setAttribute('href', url);
    //     a.setAttribute('download', "Excess_report");
    //     a.click();
    // }


    const handleExport = () => {
        const header = [
            "Date", "Session", "Menu Item", "Estimated (Persons)", 
            "Production (Persons)", "Pass Issued", "Dish count", 
            "Excess Bowl (Nos)", "Excess QTY", "Method Type", 
            "Excess RM (Kg)", "Planned RAS", "Actual RAS"
        ];

        // Prepare the data for export, including the headers
        const data = [
            header, // Add the header row
            ...filteredList.map(item => [
                formatDate(item.date),
                item.session_name,
                `${item.item_name} (${item.eng_name})`,
                parseInt(item.raw_material?.estimated_count),
                item.food_qty ?? 0,
                item.pass_issued ?? 0,
                item.final_dish_count ?? 0,
                item.excess_bowl_qty ?? 0,
                item.excess_fg_qty ?? 0,
                item.methodName?.name ?? '-',
                item.excess_rm_qty ?? 0,
                item.raw_material.ras_qty_per_100_person,
                item.production_ras ?? 0,
                item.raw_mat_per_100_person ?? 0
            ])
        ];

        // Create a new worksheet and workbook
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Excess Report");

        // Export to .xlsx file
        XLSX.writeFile(workbook, `Excess_Report_${fromDate ? '_' + formatDate(fromDate) : ''}to${toDate ? '_' + formatDate(toDate) : ''}${session?.label ? '_' + session.label : ''}.xlsx`);
    };

    
   
      


    return (
        <div className="p-4">
            {popupOpen && <Loader/>}
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-2xl font-bold">Excess Report</h1>
                <div>
                    <Link to="/">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                    </Link>
                </div>
            </div>
            <div className="relative shadow-md sm:rounded-lg">
            <DateSessionReportFilter 
            handleFilterChange={handelFilterChange} 
            // hadelSummary={hadelSummary} 
            handleExport={handleExport}
            // handelReportChange={handelReportChange} 
    
            />


                <div className="overflow-x-auto">
                <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Date</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Session</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Menu Item</th>
                            {/* <th scope="col" className="px-2 py-1 border border-black text-center">Method Type</th> */}
                            <th scope="col" className="px-2 py-1 border border-black text-center">Estimated (Persons)</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Production (Persons)</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Pass Issued</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Dish count</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Excess Bowl (Nos)</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center">Excess QTY</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Excess RM (Kg)</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Planned RAS</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Production RAS</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual RAS</th>

                            </tr>
                    </thead>
                    <tbody>
                    {filteredList.map((item, index) => (
                        // <tr key={index} className={`${item.summary ? 'bg-lime-200' : (item.methodName?.id == 1 ? 'bg-blue-200' : (item.methodName?.id == 2 ? 'bg-orange-200' : 'bg-white dark:bg-gray-800 dark:border border-black-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '))} border border-black-b`}>
                        <tr key={index} className={`${(item.methodName?.id == 1 ? 'bg-blue-200' : (item.methodName?.id == 2 ? 'bg-orange-200' : 'bg-lime-200 '))} border border-black-b`}>
                            <td className="px-2 py-1 border border-black text-center">{formatDate(item.date)}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.session_name}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.eng_name}</td>
                            <td className="px-2 py-1 border border-black text-center">{parseInt(item.raw_material?.estimated_count)}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.food_qty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.pass_issued ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.final_dish_count ?? 0}</td>
                            <td className={`px-2 py-1 border border-black text-center ${item.excess_bowl_qty > 10 ? 'bg-red-300' : (item.excess_bowl_qty > 5 && item.excess_bowl_qty <=10) ? 'bg-red-200' : ''} `}>{item.excess_bowl_qty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.excess_fg_qty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.methodName?.name ?? '-'}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.excess_rm_qty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.raw_material.ras_qty_per_100_person}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.production_ras ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.raw_mat_per_100_person ?? 0}</td>
                            
                        </tr>))}
           
                    </tbody>
                </table>
                </div>
            </div>
        </div>
  );
}

export default List;