import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../comman/config";
import Select from 'react-select';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

export default function Form({detail}) {
   
    const today = new Date().toISOString().split('T')[0];
    const [formData, setFormData] = useState({
        date : today,
        session : '',
        dish_issue : 0,
        coupons_sold:0,
        dish_issued_api:0,
        remarks: '',
    });
    const [rows, setRows] = useState([
        { id: 1, menu_item_id: "", dish_issue: '', remarks:'' },
    ]);
    const [rowCount, setRowCount] = useState(1);
    const [validationErrors, setValidationErrors] = useState({
        date: false,
        session: false,

    });
    const [popupOpen , setPopupOpen] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [mealItemList, setMealItemList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [itemDishIssueList, setItemDishIssueList] = useState([]);
    const [shortagItem, setShortageItem] = useState([]);
    const formRef = useRef(null);
    
    const navigate = useNavigate();

    useEffect(() => {
        getSessionList();
        getItemList();
    }, []);

    useEffect(() => {
        if(formData.date && formData.session.value){
            getMealItemList(formData.date, formData.session.value);
        }
        
    }, [formData.date, formData.session.value]);


    useEffect(() => {
        const fetchData = async () => {
            if(detail ){
                console.log(detail)
                const session = detail[0].session;

                const sessionItem =  sessionList.find((item) => item.id == session);
                const sessionObject = sessionItem ? {value : sessionItem.id , label : sessionItem.name} : {value : '', label : ''};
                setFormData({
                    date: detail[0].date || today,
                    session: sessionObject || '',
                    // item: itemObject || '',
                    // dish_issue: detail[0].dish_issue || 0,
                    coupons_sold:detail[0].coupons_sold || 0,
                    dish_issued_api: detail[0].dish_issued_api || 0,
                    remarks: detail[0].remarks || '',
                    
                });
            }
        }
        fetchData();
       
    }, [detail, sessionList, itemList]);

    useEffect(() => {
        const processMealItems = async () => {
            const newShortageList = [];

            for (const item of mealItemList) {
                const session = sessionList.find((i) => i.name === item.session).id;
    
                const condition = `item = '${item.menu_item_id}' AND date = '${item.date}' AND session = '${session}'`;
                const check = await getShortageList(condition);
    
                if (check) {
                    newShortageList.push(item);
                }
            }
            setShortageItem(newShortageList);
        }
        
        if (mealItemList.length > 0 && sessionList.length > 0) {
            processMealItems();
        }
    }, [mealItemList, sessionList])

    const getShortageList = async(condition) => {
        const response = await axios.get(`${baseUrl}/comman/list?table=shortage_screen&condition=${condition}`);
        if(response.data.data.length > 0){
            return true;
        }else{
            return false;
        }
    }



    const getSessionList =  async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`);
        setSessionList(response.data.data);
    }

    const getItemList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_master&condition=status=1`);
        setItemList(response.data.data);
    }

    const getMealItemList = async (date, sessionValue) => {
        const condition = ` m.track_for_report = 1 AND m.session='${sessionValue}' AND m.date='${date}'`;
        const response = await axios.get(`${baseUrl}/meal_master/item_list?condition=${condition}`);

        setMealItemList(response.data.data)
    }
 
    const handleSubmit = async (e) => {
        e.preventDefault();

        
        const newValidationErrors = {
            date: formData.date === '',
            session: formData.session === '',
            // item: formData.item === '',
            // dish_issue: formData.dish_issue === 0
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                // console.log(formData.item.value);
                setPopupOpen(true);
                // if(detail){
                //     url = `dish_issue/edit/${detail[0].id}`;
                // }else{
                    url = "dish_issue/add";
                // }

                console.log(formData)
                console.log(mealItemList)

                await axios.post(`${baseUrl}/${url}`, {
                    formData: formData,
                    itemList:mealItemList
                });
                // setPopupOpen(false);
                // navigate("/dish_issue/list");
                toast.success('Data successfully saved!');
            } catch (error) {
                toast.error('Failed to submit the form.');
                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
       
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleSelectChange = (data, option) => {
        
        if(option.name == "session"){
            setFormData((prevData) => ({
                ...prevData,
                [option.name] : data,
                item:''
               
            }));
        }else{
            setFormData((prevData) => ({
                ...prevData,
                [option.name] : data
            }));
        }
        
        
        
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [option.name]: false
        }));
    }

    const handleSwitchSelectAll = (e) => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(mealItemList); // Select all rows
        }
        setSelectAll(!selectAll);
    };
    const handleToggleSelect = (meal) => {
         
        const updatedSelectedRows = selectedRows.find((i) => i.menu_item_id == meal.menu_item_id)
            ? selectedRows.filter(item => item.menu_item_id !== meal.menu_item_id) // Deselect
            : [...selectedRows, meal]; // Select
        setSelectedRows(updatedSelectedRows);
    };

    const handleSave = (e) => {
        e.preventDefault();
        const prevDishIssuesMap = new Map();
        itemDishIssueList.forEach(item => {
            prevDishIssuesMap.set(item.menu_item_id, item.dish_issue);
        });

        const updateMealList = mealItemList.map((item) => {
            const selectedItem = selectedRows.find((selectedItem) => selectedItem.menu_item_id === item.menu_item_id);
            const itemCheck = shortagItem.find((selectedItem) => selectedItem.menu_item_id === item.menu_item_id);
            if(itemCheck){
                return { ...item, dish_issue: item.dish_issue ?? '' };
            }else if(selectedItem && !itemCheck){
                // console.log(item.dish_issue);
                // console.log(formData.dish_issue);
                return {...item, dish_issue: formData.dish_issue};
            }else{
                return { ...item, dish_issue: item.dish_issue ?? '' };
            }
        })
        console.log(updateMealList);
        setMealItemList(updateMealList);
    }

    useEffect(() => {
        if(selectedRows.length > 0 && formData.dish_issue){
            const e = new Event("click");
            handleSave(e);
        }
       
    }, [formData.dish_issue, selectedRows]);
  return (
 
    <form ref={formRef} className="space-y-12">
        <Toaster position="top-center" reverseOrder={false} />
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8  w-64">
        <div className="sm:col-span-2">
            <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">Date</label>
            <input
                type="date"
                name="date"
                id="date"
                value={formData.date}
                onChange={handleInputChange}
                className={`block w-full rounded-md border ${validationErrors.date ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
            />
            {validationErrors.date && <div className="text-red-500">Please enter date.</div>}
        </div>
        <div className="sm:col-span-2">
            <label htmlFor="session" className="block text-sm font-medium leading-6 text-gray-900">Session</label>
            <Select
                classNamePrefix="select"
                value={formData.session}
                name="session"
                onChange={(selectedOption, name) => {
                    handleSelectChange(selectedOption, name);
                }}
                options={sessionList.map(item => ({
                    value: item.id,
                    label: item.name
                }))}
                filterOption={(option, inputValue) => {
                    return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
            />
            {validationErrors.session && <div className="text-red-500">Please select a session.</div>}
        </div>
        <div className="sm:col-span-2">
            <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900">Coupons sold</label>
            <input
                id="coupons_sold"
                name="coupons_sold"
                type="number"
                step="0.01"
                placeholder='Coupons sold'
                value={formData.coupons_sold}
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                className={`block w-full rounded-md border ${validationErrors.coupons_sold ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
            />
            {/* <button disabled={selectedRows.length <= 0} className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"  onClick={(e) => {handleSave(e);}}>Apply</button> */}
        </div>
        <div className="sm:col-span-2">
            <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900">Dish Issued - API</label>
            <input
                id="dish_issued_api"
                name="dish_issued_api"
                type="number"
                step="0.01"
                placeholder='Dish Issued - API'
                value={formData.dish_issued_api}
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                className={`block w-full rounded-md border ${validationErrors.dish_issued_api ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
            />
            {/* <button disabled={selectedRows.length <= 0} className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"  onClick={(e) => {handleSave(e);}}>Apply</button> */}
        </div>
        <div className="sm:col-span-2">
            <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900">Remarks</label>
            <input
                id="remarks"
                name="remarks"
                type="text"
                value={formData.remarks}
                onChange={handleInputChange}
                className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
        </div>
         <div className="sm:col-span-2">
            <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900">Final Dish Count</label>
            <input
                id="dish_issue"
                name="dish_issue"
                type="number"
                step="0.01"
                placeholder='Final Dish Count'
                value={formData.dish_issue}
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                className={`block w-full rounded-md border ${validationErrors.dish_issue ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
            />
            {/* <button disabled={selectedRows.length <= 0} className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"  onClick={(e) => {handleSave(e);}}>Apply</button> */}
        </div>
        
    </div>
    {mealItemList.length > 0 && <table className="w-1/2 !important">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3"><input type="checkbox" onChange={handleSwitchSelectAll} checked={selectAll} /></th>
                <th scope="col" className="px-6 py-3">Item Name</th>
                <th scope="col" className="px-6 py-3">Dish Isuue</th>
                
            </tr>
        </thead>
        <tbody>
            {mealItemList.map((item) => (
            <tr key={item.id} scope="row" className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className={`px-6 py-3 text-center`}><input type="checkbox" checked={selectedRows.find((i) => i.menu_item_id == item.menu_item_id)} onChange={() => handleToggleSelect(item)} /></td>
                <td className={`px-6 py-3 text-center`}>{`${item.item_name} (${item.eng_name})`}</td>
                <td className={`px-6 py-3 text-center`}>{item.dish_issue}</td>
            </tr>))}
        </tbody>
    </table>}
    <div className="mt-6 flex items-center  gap-x-6">
        <Link to="/dish_issue/list">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
        </Link>
        <button
            onClick={handleSubmit}
            type="submit"
            className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            Save
        </button>
    </div>
  </form>
  

            )
        }