import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { baseUrl, timeList, batchSize, formatDate } from "../comman/config";
import Select from "react-select";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useNavigate, Link } from "react-router-dom";
import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from "react-hot-toast";
import ModalContent from "../components/ModalContent";
import * as XLSX from 'xlsx';
import Loader from "../components/Loader";

// import Pagination from "react-js-pagination";

function ProductionSupport() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [rmDetailForItem, setRMDetailForItem] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [vesselList, setVesselList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalActucalRawQty, setTotalActucalRawQty] = useState(0);
  const isEffectRan = useRef(false); 
  const [showModal, setShowModal] = useState(false);
  const [batchIndex, setBatchIndex] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getVesselList();
    getIngredientList();
  }, []);
  useEffect(() => {
    // Create initial formData and validationErrors based on mealList length
    const initialFormData = Array.from({ length: batchSize }, (_, index) => ({
      batch: index + 1,
      batch_raw_material_qty: "",
      planned_batch_raw_qty: "",
      planned_vessel_id: "",
      vessel_id: "",
      planned_timeslot_start: "",
      planned_timeslot_end: "",
      timeslot_start: "",
      timeslot_end: "",
      requisition_no: "",
      remarks: "",
      ingredientDetail: []
    }));
    setFormData(initialFormData);
  }, [batchSize]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      getRMDetailForItem();
    }
  }, [mealList, activeIndex]);

  useEffect(()=>{
    if(batchIndex >=0){
      setShowModal(true)
    }
  },[batchIndex])

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null ) {
      const itemId =
        mealList.find((item, index) => index === activeIndex).menu_item_id ??
        null;
      console.log(itemId);
      const fetchDetail = async () => {
        setLoading(true)
        const condition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}'`;
        const plannedResponse = await axios.get(
          `${baseUrl}/comman/list?table=production_planning&condition=${condition}`
        );

        const supportResponse = await axios.get(
          `${baseUrl}/comman/list?table=production_support&condition=${condition}`
        );

        const plannedDetail = plannedResponse.data.data;
        const supportDetail = supportResponse.data.data;

        const RecipeData = await axios.get(`${baseUrl}/comman/list?table=receipe_master&condition=menu_item_id='${itemId}'`);

        const RecipeDbData = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${condition} GROUP BY ingredient_id order by id asc`);
        // console.log(RecipeData.data.data);
        let ddArray = [];
        if(RecipeDbData.data.data.length > 0){
          RecipeDbData.data.data.forEach((item, index) => {
            const data = ingredientList.find((v) => v.id == item.ingredient_id);
            console.log(item);
            const stdQty = ((RecipeData.data?.data[index]?.qty ?? 0) / 50);
            const dd = {ingredient_id : data.id, name : data.ingredient, required_qty: 0,  issued_qty: 0, excess_qty: 0, consume_qty: 0, return_qty : 0, store : data.store}
            ddArray.push(dd);
          })
        }else if(RecipeData.data.data.length > 0){
          RecipeData.data.data.forEach((item) => {
            const data = ingredientList.find((v) => v.id == item.ingredient_id);
            // console.log(data);
            // const stdQty = (parseInt(item.qty) / 50).toFixed(3);
            const dd = {ingredient_id : data.id, name : data.ingredient, required_qty: 0,  issued_qty: 0, excess_qty: 0, consume_qty: 0, return_qty : 0, store: data.store}
            ddArray.push(dd);
          })
        }else{
          ddArray = [];
        }
        if( itemId && date && session){
          getDetailDispaly(plannedDetail, supportDetail, ddArray, itemId, date, session);
        }
        setLoading(false)
      };
      fetchDetail();
      isEffectRan.current = true;
    }
  }, [mealList, activeIndex]);

  const getDetailDispaly = async (plannedDetail, supportDetail, ddArray,  itemId, date, session) => {
    try {
      if (plannedDetail && supportDetail) {
        const initialFormData = Array.from(
          { length: batchSize },
          (_, index) => ({
            batch: index + 1,
            batch_raw_material_qty: "",
            planned_batch_raw_qty: "",
            planned_vessel_id: "",
            vessel_id: "",
            planned_timeslot_start: "",
            planned_timeslot_end: "",
            timeslot_start: "",
            timeslot_end: "",
            requisition_no: "",
            remarks: "",
            ingredientDetail: ddArray
          })
        );
        const updatedFormData = await Promise.all(initialFormData.map(async(initialItem) => {
          const plannedObject = plannedDetail.find(
            (object) => object.batch === initialItem.batch
          );
          const supportObject = supportDetail.find(
            (object) => object.batch === initialItem.batch
          );
          if( itemId && date && session){
            const condition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}' AND batch = '${initialItem.batch}'`;
            const RecipeData = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${condition} `);

            // const SupportRecipeData = await axios.get(`${baseUrl}/comman/list?table=production_receipe_support&condition=${condition} `);

            let updatedIngredientDetail;
            if(RecipeData.data.data.length > 0){
              updatedIngredientDetail = initialItem.ingredientDetail.map((item, index) => {
                const receipeFind = RecipeData.data.data.find((val) => val.ingredient_id == item.ingredient_id);
                if(receipeFind){
                  return {ingredient_id : item.ingredient_id, name : item.name, required_qty: receipeFind.required_qty,  issued_qty: receipeFind.issued_qty, received_qty: receipeFind.received_qty, excess_qty: receipeFind.excess_qty, consume_qty: receipeFind.consume_qty, return_qty : receipeFind.return_qty, store: item.store , db_id : receipeFind.id};
                }
                
             })
           
           }else{
              updatedIngredientDetail = initialItem.ingredientDetail.map((item) => {
               return { ...item};
             })
           }
            initialItem.ingredientDetail = updatedIngredientDetail;
          }
          if (plannedObject && !supportObject) {
            const plannedVesselId = plannedObject.vessel_id;
            const plannedVesselItem = vesselList.find(
              (item) => item.id == plannedVesselId
            );
            const plannedVesselObject = plannedVesselItem
              ? { value: plannedVesselItem.id, label: plannedVesselItem.name }
              : { value: "", label: "" };

            const plannedTimeslot = plannedObject.start_time;
            const plannedTimeslotItem = timeList.find(
              (item) => item.value == plannedTimeslot
            );
            const plannedTimeslotObject = plannedTimeslotItem
              ? {
                  value: plannedTimeslotItem.value,
                  label: plannedTimeslotItem.label,
                }
              : { value: "", label: "" };
            
            const plannedEndTimeslot = plannedObject.end_time;
            const plannedEndTimeslotItem = timeList.find((item) => item.value == plannedEndTimeslot);
            const plannedEndTimeslotObject = plannedEndTimeslotItem
                ? {
                    value: plannedEndTimeslotItem.value,
                    label: plannedEndTimeslotItem.label,
                  }
                : { value: "", label: "" };

            plannedObject.planned_vessel_id = plannedVesselObject;
            plannedObject.planned_timeslot_start = plannedTimeslotObject;
            plannedObject.planned_timeslot_end = plannedEndTimeslotObject;
            plannedObject.planned_batch_raw_qty = plannedObject.planned_batch_raw_qty;
            plannedObject.requisition_no = plannedObject.requisition_no;
            console.log(plannedObject.requisition_no);
            plannedObject.remarks = "";
            
            return { ...initialItem, ...plannedObject };
          } else if (supportObject || plannedObject) {

            const supportVesselId = supportObject.vessel_id;
            const supportVesselItem = vesselList.find(
              (item) => item.id == supportVesselId
            );
            const supportVesselObject = supportVesselItem
              ? { value: supportVesselItem.id, label: supportVesselItem.name }
              : { value: "", label: "" };
            const timeslotStart = supportObject.timeslot_start;
            const timeslotStartItem = timeList.find(
              (item) => item.value == timeslotStart
            );
            const timeslotStartObject = timeslotStartItem
              ? {
                  value: timeslotStartItem.value,
                  label: timeslotStartItem.label,
                }
              : { value: "", label: "" };

            const timeslotEnd = supportObject.timeslot_end;
            const timeslotEndItem = timeList.find(
              (item) => item.value == timeslotEnd
            );
            const timeslotEndObject = timeslotEndItem
              ? { value: timeslotEndItem.value, label: timeslotEndItem.label }
              : { value: "", label: "" };
              
            const plannedVesselId = plannedObject?.vessel_id ?? 0;
            const plannedVesselItem = vesselList.find((item) => item.id == plannedVesselId);
            const plannedVesselObject = plannedVesselItem ? { value: plannedVesselItem.id, label: plannedVesselItem.name } : { value: "", label: "" };
  
            const plannedTimeslot = plannedObject?.start_time ?? 0;
            const plannedTimeslotItem = timeList.find((item) => item.value == plannedTimeslot);
            const plannedTimeslotObject = plannedTimeslotItem ? {value: plannedTimeslotItem.value, label: plannedTimeslotItem.label} : { value: "", label: "" };

            const plannedEndTimeslot = plannedObject?.end_time;
            const plannedEndTimeslotItem = timeList.find((item) => item.value == plannedEndTimeslot);
            const plannedEndTimeslotObject = plannedEndTimeslotItem
                ? {
                    value: plannedEndTimeslotItem.value,
                    label: plannedEndTimeslotItem.label,
                  }
                : { value: "", label: "" };
  
            initialItem.planned_vessel_id = plannedVesselObject;
            console.log(plannedObject);
            initialItem.planned_timeslot_start = plannedTimeslotObject;
            initialItem.planned_timeslot_end = plannedEndTimeslotObject;
            initialItem.batch_raw_material_qty = supportObject.batch_raw_material_qty ?? 0;
            initialItem.planned_batch_raw_qty = plannedObject?.planned_batch_raw_qty ?? 0;
            initialItem.vessel_id = supportVesselObject;
            initialItem.timeslot_start = timeslotStartObject;
            initialItem.timeslot_end = timeslotEndObject;
            initialItem.remarks = supportObject.remarks;
            initialItem.requisition_no = plannedObject?.requisition_no;
            // console.log(plannedObject.requisition_no);
            return { ...initialItem };
          } else {
            return initialItem;
          }
        }));
        // console.log(updatedFormData);
        setFormData(updatedFormData);
      } else {
        setTotalActucalRawQty(0);
        const initialFormData = Array.from(
          { length: batchSize },
          (_, index) => ({
            batch: index + 1,
            batch_raw_material_qty: "",
            planned_batch_raw_qty: "",
            planned_vessel_id: "",
            vessel_id: "",
            planned_timeslot: "",
            timeslot_start: "",
            timeslot_end: "",
            requisition_no: "",
            remarks: "",
            ingredientDetail: ddArray
          })
        );
        setFormData(initialFormData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const sum = formData.reduce((total, obj) => {
      const qty = parseFloat(obj.batch_raw_material_qty);
      return isNaN(qty) ? total : total + qty;
    }, 0);
    setTotalActucalRawQty(sum);
  }, [formData]);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const getVesselList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=vessel_master&condition=status=1`
    );
    setVesselList(response.data.data);
  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const list = response.data.data.filter((item) => item.status == 1);
    setIngredientList(list);
  };

  const getRMDetailForItem = async () => {
    setLoading(true)
    const item_id = mealList.find((item, index) => index == activeIndex);
    const condition = `date = '${date}' AND session = '${session.value}' AND menu_item_id = '${item_id.menu_item_id}'`;
    const response = await axios.get(
      `${baseUrl}/comman/list?table=raw_material&condition=${condition}`
    );
    setRMDetailForItem(response.data.data);
    setLoading(false)
  };

  const handelFilterChange = async (date, session) => {
    setDate(date);
    setSession(session);
    if (date && session) {
      const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`
      );
      setMealList(response.data.data);
    }
  };

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    if(name == "batch_raw_material_qty"){
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index].ingredientDetail.map((item) => {
          const consume = updatedFormData[index].planned_batch_raw_qty > 0 ? ((item?.issued_qty ?? 0) * value / updatedFormData[index].planned_batch_raw_qty).toFixed(3) : 0;
          const excess = ((item?.issued_qty ?? 0) - consume).toFixed(3);
          item.consume_qty = consume;
          item.excess_qty = excess;
          item.return_qty = excess;
        })
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        return updatedFormData;
      });
    }else{
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        return updatedFormData;
      });
    }
    
  };

  const handleSelectChange = async (index, data, option) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        [option.name]: data,
      };
      return updatedFormData;
    });
  };

  const handleSave = async (e, index) => {
    console.log(formData[index])
    e.preventDefault();
    setLoading(true)
    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const combineData = {
      formData: formData[index],
      date: date,
      session: session,
      menu_item_id: menu_item_id,
    };
    console.log(combineData);
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/production_support/batch`, combineData);
      toast.success("Data successfully saved!");
      // window.location.reload();
    } catch (error) {
      toast.error("Failed to submit the form.");
      // setPopupOpen(false);
      console.log(error);
    }
    setLoading(false)
  };

  const handlePlannedQtyChange = (e, formIndex, ingredientIndex, name) => {
    const { value } = e.target;
    
    setFormData((prevFormData) => {
      return prevFormData.map((batch, index) => {
          if (index + 1 === formIndex) {
              return {
                  ...batch,
                  ingredientDetail: batch.ingredientDetail.map((ingredient, idx) => {
                      if (idx === ingredientIndex) {
                        if(name == "issued_qty"){
                          const consume = batch.planned_batch_raw_qty > 0 ? (((batch?.batch_raw_material_qty ?? 0) * value) / batch.planned_batch_raw_qty).toFixed(3) : 0;
                          const excess = (value - consume).toFixed(3);
                          return {
                            ...ingredient,
                            [name]: value,
                            ["consume_qty"] : consume,
                            ["excess_qty"] : excess,
                            ["return_qty"] : excess,
                          };
                        }else if(name == "consume_qty"){
                          console.log(ingredient.issued_qty);
                          const excess = (ingredient.received_qty - value).toFixed(3);
                          return {
                            ...ingredient,
                            [name]: value,
                            ["excess_qty"] : excess,
                            ["return_qty"] : excess,
                          }
                        }else if(name == "received_qty"){
                          const excess = (value - ingredient.consume_qty).toFixed(3)
                          return {
                            ...ingredient,
                            [name]: value,
                            ["excess_qty"] : excess,
                            ["return_qty"] : excess,
                          }
                          }{
                          return {
                            ...ingredient,
                            [name]: value
                        };
                        }
                          
                      }
                      return ingredient;
                  })
              };
          }
          return batch;
      });
    });
  };

  const handleAddIngredient = async(ingredient) => {
    // console.log(formData[activeIndex].ingredientDetail)
    console.log(batchIndex,ingredient)
    if(batchIndex >= 0 && formData[batchIndex]){
      const ob = {
        consume_qty: null,
        excess_qty: null,
        ingredient_id:ingredient.value,
        issued_qty:null,
        name: ingredient.name,
        unit:ingredient.data.unit,
        received_qty: null,
        required_qty: null, 
        return_qty:null,
        store: ingredient.data.store
      };
  
      console.log(ingredient)
  
      const updatedFormData = [...formData];
  
      // Add the new ingredient to the ingredientDetail array of the active form
      updatedFormData[batchIndex].ingredientDetail = [
        ...updatedFormData[batchIndex].ingredientDetail,
        ob,
      ];
  
      // Update the state with the new form data
      setFormData(updatedFormData);
      setBatchIndex(null)
    }
} 

const handleExport = async (e, index) => {
  e.preventDefault();

  const menu_item = mealList.find((item, i) => i === activeIndex);
  const wb = XLSX.utils.book_new();

  // Group ingredients by store
  // const ingredientsByStore = {};
  // console.log(formData[index].ingredientDetail)
  // formData[index].ingredientDetail.forEach(item => {
  //     const storeName = item.store?.name || 'Main Store';
  //     if (!ingredientsByStore[storeName]) {
  //         ingredientsByStore[storeName] = [];
  //     }
  //     ingredientsByStore[storeName].push(item);
  // });
  // console.log(ingredientsByStore)

  const wsData = [
    [""], // Placeholder for merged cell
    [],
    ["Requisition No."],
    ["Date :", date, "Session :", session.label, "Item :", menu_item.item_name],
    ["Batch :", formData[index].batch, "Vessel :", formData[index].vessel_id?.label, "Plan Batch Qty :", formData[index].planned_batch_raw_qty],
    [],
    [],
    ["Sr. No.", "નામ", "Unit", "મોકલેલ", "મળેલ", "વપરાયેલ", "બચેલું", "રીટર્ન"]
  ];

  formData[index].ingredientDetail.forEach((item, idx) => {
    wsData.push([
      idx + 1,
      item.name,
      item.unit?.name,
      item.issued_qty,
      item.received_qty,
      item.consume_qty,
      item.excess_qty,
      item.return_qty
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet(wsData);
  // Create sheets for each store
  // Object.entries(ingredientsByStore).forEach(([storeName, items]) => {
  //     const ws = XLSX.utils.aoa_to_sheet([
  //         [""], // Placeholder for merged cell
  //         [],
  //         ["Requisition No."],
  //         ["Date :", date, "Session :", session.label, "Item :", menu_item.item_name],
  //         ["Batch :", formData[index].batch, "Vessel :", formData[index].vessel_id?.label, "Plan Batch Qty :", formData[index].planned_batch_raw_qty],
  //         [],
  //         [],
  //         ["Store:", storeName], // Add store name
  //         ["Sr. No.", "Ingredients", "Unit", "Issued Qty.","Received Qty.", "Excess Qty (support)", "Consumed Qty.", "Return to Stores (Qty.)"],
  //         ...items.map((item, idx) => [
  //             idx + 1,
  //             item.name,
  //             item.unit?.name,
  //             item.issued_qty,
  //             "",
  //             "",
  //             "",
  //             ""
  //         ])
  //     ]);

      // Merge cells as needed
      ws['!merges'] = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
          { s: { r: 2, c: 1 }, e: { r: 2, c: 7 } },
          { s: { r: 3, c: 4 }, e: { r: 3, c: 5 } },
          { s: { r: 4, c: 4 }, e: { r: 4, c: 5 } },
          { s: { r: 3, c: 6 }, e: { r: 3, c: 7 } },
          { s: { r: 4, c: 6 }, e: { r: 4, c: 7 } },
          { s: { r: 6, c: 0 }, e: { r: 6, c: 7 } } // Merge store name cell
      ];

      // Set cell values and styles
      ws['A1'] = {
          v: "Production Support Slip( Store / Support )",
          s: {
              alignment: { horizontal: "center" },
              font: { sz: 20, color: { rgb: "000000" } },
              fill: { patternType: "solid", fgColor: { rgb: "D8D8D8" } }
          }
      };

      ws['A3'] = { v: "Requisition No." };
      ws['B3'] = {
          v: formData[index].requisition_no,
          s: {
              alignment: { horizontal: "center" },
              font: { sz: 12, color: { rgb: "000000" } }
          }
      };

      ws['G4'] = { v: menu_item.item_name };
      ws['G5'] = { v: formData[index].planned_batch_raw_qty };

      const wscols = [
          { wch: 10 }, // Reduced width for Date and Batch columns
          { wch: 10 }, // Requisition No. column
          { wch: 10 }, // Date column
          { wch: 10 }, // Session column
          { wch: 10 }, // Item column
          { wch: 10 }, // Batch column
          { wch: 10 }, // Vessel column
          { wch: 10 }, // Plan Batch Qty column
          { wch: 5 },  // Ingredient ID column
          { wch: 10 }, // Name column
          { wch: 5 },  // Unit column
          { wch: 10 }, // Std Qty column
          { wch: 10 }, // Planned Qty column
      ];
      ws['!cols'] = wscols;

      // Add worksheet to workbook
  //     XLSX.utils.book_append_sheet(wb, ws, `Store_${storeName}`);
  // });

  XLSX.utils.book_append_sheet(wb, ws, 'All_Ingredients');
  // Generate XLSX file and trigger download
  XLSX.writeFile(wb, `Production_Support_${formatDate(date)}_${session?.label}_${menu_item?.item_name}.xlsx`);
};

  useEffect(() => {
      console.log(formData);
  }, [formData]);
  return (
    <div className="">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
          Production Support
        </h1>
        <Toaster position="top-center" reverseOrder={false} />
        {/* <div>
                    <Link to="/menu/add">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Menuitem</button>
                    </Link>
                </div> */}
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DateSessionFilter handleFilterChange={handelFilterChange} />
        <div>
          {mealList.map((item, index) => (
            <div key={index} className="border-b mt-4 ">
              <div
                className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
                onClick={() => handleClick(index)}
              >
                <span >{`${item.item_name} (${item.eng_name})`}</span>
                {index === activeIndex ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </div>
              {index === activeIndex && (
                <div className="space-y-2 p-4 bg-white">
                  <div className="lg:w-96 ">
                    <div className="flex flex-wrap justify-between">
                      <div className="sm:text-xs lg:text-base font-bold">
                        Planned Raw Material Qty (Kgs.) :
                      </div>
                      <div>{rmDetailForItem?.[0]?.planned_rm_qty ?? 0}</div>
                    </div>

                    <div className="flex flex-wrap justify-between">
                      <div className="sm:text-sm lg:text-base font-bold">
                        Total Actual Raw Material Qty. (Kgs.) :
                      </div>
                      <div>{totalActucalRawQty ?? 0}</div>
                    </div>
                    <div className="flex flex-wrap justify-between">
                      <div className="sm:text-sm lg:text-base font-bold">
                        Pending Actual Raw Material Qty. (Kgs.) :
                      </div>
                      <div>
                        {isNaN(
                          rmDetailForItem?.[0]?.planned_rm_qty -
                            totalActucalRawQty
                        )
                          ? 0
                          : rmDetailForItem?.[0]?.planned_rm_qty -
                            totalActucalRawQty}
                      </div>
                    </div>

                    {/* Replace "estimatedCount" with your actual value */}
                  </div>
                    {batchIndex >= 0 && formData[batchIndex] && <ModalContent showModal={showModal} setShowModal={setShowModal} ingredientList={ingredientList} handleAddIngredient={handleAddIngredient} ingredientDetail={formData[batchIndex].ingredientDetail} />}
                  <div className="flex  overflow-x-auto">
                    <div className="flex mt-10 whitespace-nowrap flex-col">
                      <div className="font-bold mt-3">Batch</div>
                      <div className="font-bold mt-4">Requisition No.</div>
                      <div className="font-bold mt-4">
                        Batch Raw Material Qty.
                      </div>
                      <div className="font-bold  mt-8">Vessel Name</div>
                      <div className="font-bold  mt-8">Time slot</div>
                      <div className="font-bold  mt-8">
                        Actual start/end time
                      </div>
                      <div className="font-bold  mt-7">Remarks (stores)</div>
                      <div className="font-bold "></div>
                    </div>
                    {Array.from({ length: batchSize }, (_, index) => (
                      <div
                        key={index}
                        className="gap-5 mb-4 mt-10 ms-2 flex-shrink-0 w-auto"
                      >
                        <div className="border p-2">{index + 1}</div>
                        <div className="border p-2">
                          {/* {formData?.[index]?.requisition_no != formData?.[index]?.requisition_no ?? "-"} */}
                          {formData?.[index]?.requisition_no ?? "-"}
                        </div>
                        <div className="border p-2 flex">
                          <input
                            type="number"
                            step="0.01"
                            id="planned_batch_raw_qty"
                            placeholder="Planned Batch Raw Qty"
                            disabled
                            name="planned_batch_raw_qty"
                            value={formData?.[index]?.planned_batch_raw_qty}
                            onChange={handleInputChange(index)}
                            onWheel={(e) => e.target.blur()}
                            className="w-full border rounded-md placeholder:text-sm "
                          />
                          <input
                            type="number"
                            step="0.01"
                            id="batch_raw_material_qty"
                            placeholder="Batch Raw Material Qty."
                            name="batch_raw_material_qty"
                            value={formData?.[index]?.batch_raw_material_qty}
                            onChange={handleInputChange(index)}
                            onWheel={(e) => e.target.blur()}
                            className="w-full border p-1 rounded-md ms-2 placeholder:text-sm bg-orange-50" 
                          />
                        </div>
                        <div className="border p-2 flex">
                          <Select
                            classNamePrefix="select"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                            }}
                            name="planned_vessel_id"
                            placeholder="Planned Vessel Name"
                            className="ms-2 w-60"
                            value={formData?.[index].planned_vessel_id}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            options={vesselList.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            isDisabled={true}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                          <Select
                            classNamePrefix="select"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: '#FFFAF0', 
                                fontSize: '16px', 
                              }),
                            }}
                            name="vessel_id"
                            
                            value={formData?.[index].vessel_id}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            options={vesselList.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            className="ms-2 w-64"
                            placeholder="Vessel Name"
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>
                        <div className="border p-2 flex">
                          <Select
                            classNamePrefix="select"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              
                            }}
                            name="planned_timeslot_start"
                            placeholder="Start Time slot"
                            value={formData?.[index].planned_timeslot_start}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            className="placeholder:text-sm ms-2 w-60"
                            options={timeList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            isDisabled={true}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                          <Select
                            classNamePrefix="select"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              
                            }}
                            name="planned_timeslot_end"
                            placeholder="End Time slot"
                            value={formData?.[index].planned_timeslot_end}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            className="placeholder:text-sm ms-2 w-60"
                            options={timeList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            isDisabled={true}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>
                        <div className="border p-2 flex">
                          <Select
                            classNamePrefix="select"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: '#FFFAF0', 
                                fontSize: '16px', 
                              }),
                            }}
                            name="timeslot_start"
                            className="placeholder:text-sm ms-2 w-60"
                            value={formData?.[index].timeslot_start}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            placeholder="Start time"
                            options={timeList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                          <Select
                            classNamePrefix="select"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: '#FFFAF0', 
                                fontSize: '16px', 
                              }),
                            }}
                            className="placeholder:text-sm ms-2 w-60"
                            name="timeslot_end"
                            value={formData?.[index].timeslot_end}
                            placeholder="End time "
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            options={timeList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>
                        <div className="border p-2">
                          <input
                            type="text"
                            id="remarks"
                            name="remarks"
                            placeholder="Remarks"
                            value={formData?.[index]?.remarks}
                            onChange={handleInputChange(index)}
                            className="w-full border p-1 rounded-md placeholder:text-sm bg-orange-50"
                          />
                        </div>
                        {formData[index].ingredientDetail.length > 0 && <div>
                            <table className="border w-full space-x-1">
                              <thead className="space-x-1">
                                <tr>
                                  <th className="px-1">નામ</th>
                                  {/* <th className="px-1">Req. Qty</th> */}
                                  <th className="px-1">મોકલેલ</th>
                                  <th className="px-1">મળેલ</th>
                                  <th className="px-1">વપરાયેલ</th>
                                  <th className="px-1">બચેલું</th>
                                  <th className="px-1">રીટર્ન</th>
                                </tr>
                              </thead>
                              <tbody>
                              {formData[index].ingredientDetail.map((item, idx) => (
                                <tr key={idx} className="border">
                                  <td className="text-center">{item.name}</td>
                                  {/* <td className="text-center">{item.required_qty}</td> */}
                                  <td className="text-center"><input type="number" onWheel={(e) => e.target.blur()} disabled step="0.01" id={`issued_qty_${index}_${idx}`} placeholder="મોકલેલ"  onChange={(e) => handlePlannedQtyChange(e, index+1, idx, "issued_qty")}  name={`issued_qty_${index}_${idx}`} value={item.issued_qty} className="w-20 p-1 border rounded-md placeholder:text-sm bg-orange-50"/></td>
                                  <td className="text-center"><input type="number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}  step="0.01" id={`received_qty_${index}_${idx}`} placeholder="મળેલ"  onChange={(e) => handlePlannedQtyChange(e, index+1, idx, "received_qty")}  name={`received_qty_${index}_${idx}`} value={item.received_qty} className="w-20 p-1 border rounded-md placeholder:text-sm bg-orange-50"/></td>
                                  <td className="text-center"><input type="number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}} step="0.01" id={`consume_qty_${index}_${idx}`} placeholder="વપરાયેલ"  onChange={(e) => handlePlannedQtyChange(e, index+1, idx, "consume_qty")}  name={`consume_qty_${index}_${idx}`} value={item.consume_qty} className="w-20 p-1 border rounded-md placeholder:text-sm bg-orange-50"/></td>
                                  <td className="text-center"><input type="number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}} step="0.01" id={`excess_qty_${index}_${idx}`} placeholder="બચેલું" onChange={(e) => handlePlannedQtyChange(e, index+1, idx, "excess_qty")}  name={`excess_qty_${index}_${idx}`} value={item.excess_qty} className="w-20 p-1 border rounded-md placeholder:text-sm" disabled/></td>
                                  <td className="text-center"><input type="number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}} step="0.01" id={`return_qty_${index}_${idx}`} placeholder="રીટર્ન" onChange={(e) => handlePlannedQtyChange(e, index+1, idx, "return_qty")}  name={`return_qty_${index}_${idx}`} value={item.return_qty} className="w-20 p-1 border rounded-md placeholder:text-sm bg-orange-50"/></td>
                                  
                                </tr>
                              ))}
                              </tbody>
                            </table>
                        </div>}
                        <div className="border p-2 flex justify-between">
                          <div>
                          <button
                            onClick={(e) => {
                              handleExport(e, index);
                            }}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2"
                          >
                            Export
                          </button>
                          <button
                            onClick={() => setBatchIndex(index)}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2"
                          >
                            Add Ingredient
                          </button>
                          </div>
                          <button
                            onClick={(e) => {
                              handleSave(e, index);
                            }}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductionSupport;
